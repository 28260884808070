body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #121212;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

.centered {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
}
.myInput:focus,
.myInput:active {
    outline: none;
    border: none;
}
.glow {
  box-shadow: 0 -50px 200px 50px rgba(3, 170, 199, 0.7);
}
.glower {
  box-shadow: 0 0 200px 30px rgba(3, 170, 199, 0.7);
}
.positive {
  color: #40C9A6;
}
.negative {
  color: #F24D53;
}
.answer {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.9s ease;
}
.answ {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.9s ease;
}
@media (min-width: 769px) {
  /* Apply max-height: 300px for screen sizes greater than 768px */
  .answ {
    max-height: 300px;
  }
}
.faq.active .answer {
  max-height: 300px;
}
.faq.active .answ {
  max-height: 300px;
}

.faq.active img {
  transform: rotate(180deg);
}

img {
  transition: transform 0.5s ease-in;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
}
/* menu  */

.demo {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  background: #fff;
  height: 100vh;
  /* background-image: linear-gradient(to top, #dad4ec 0%, #dad4ec 1%, #f3e7e9 100%); */
}
.demo .menu-icon {
  transform: scale(1.5);
}
:root {
  --bar-bg: #fff;
}
.menu-icon {
  position: relative;
  width: 50px;
  height: 50px;
  cursor: pointer;
}
.menu-icon .menu-icon__cheeckbox {
  display: block;
  width: 100%;
  height: 100%;
  position: relative;
  cursor: pointer;
  z-index: 2;
  -webkit-touch-callout: none;
  position: absolute;
  opacity: 0;
}
.menu-icon div {
  margin: auto;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 22px;
  height: 12px;
}
.menu-icon span {
  position: absolute;
  display: block;
  width: 100%;
  height: 2px;
  background-color: var(--bar-bg, #fff);
  border-radius: 1px;
  transition: all 0.2s cubic-bezier(0.1, 0.82, 0.76, 0.965);
}
.menu-icon span:first-of-type {
  top: 0;
}
.menu-icon span:last-of-type {
  bottom: 0;
}
.menu-icon.active span:first-of-type, .menu-icon .menu-icon__cheeckbox:checked + div span:first-of-type {
  transform: rotate(45deg);
  top: 5px;
}
.menu-icon.active span:last-of-type, .menu-icon .menu-icon__cheeckbox:checked + div span:last-of-type {
  transform: rotate(-45deg);
  bottom: 5px;
}
.menu-icon.active:hover span:first-of-type, .menu-icon.active:hover span:last-of-type, .menu-icon:hover .menu-icon__cheeckbox:checked + div span:first-of-type, .menu-icon:hover .menu-icon__cheeckbox:checked + div span:last-of-type {
  width: 22px;
}
@media (min-width: 1024px) {
  .menu-icon:hover span:first-of-type {
    width: 26px;
 }
  .menu-icon:hover span:last-of-type {
    width: 12px;
 }
}
